import { HabitData, HabitDay } from "./utils/dataGenerator";
import moment from "moment";

export function setHabitDay(day: HabitDay): setHabitDayActionType {
  return {
    type: "SET_HABIT_DAY",
    payload: { day }
  };
}

export interface setHabitDayActionType {
  type: "SET_HABIT_DAY";
  payload: {
    day: HabitDay;
  };
}

export function setFullData(data: HabitData): setFullDataActionType {
  return {
    type: "SET_FULL_DATA",
    payload: { data }
  };
}

export interface setFullDataActionType {
  type: "SET_FULL_DATA";
  payload: {
    data: HabitData;
  };
}

const initialState: HabitData = {};
function habitSquareReducer(
  state = initialState,
  action: setHabitDayActionType | setFullDataActionType
) {
  switch (action.type) {
    case "SET_FULL_DATA": {
      return action.payload.data;
    }
    case "SET_HABIT_DAY": {
      const thisAction = action as setHabitDayActionType;
      const todaysDate = moment()
        .startOf("day")
        .toISOString();
      if (state[todaysDate]) {
        const newState = {
          ...state,
          [todaysDate]: thisAction.payload.day
        };
        return newState;
      } else {
        const newState = {
          ...state,
          [todaysDate]: thisAction.payload.day
        };
        return newState;
      }
    }

    default:
      return state;
  }
}

export default habitSquareReducer;
