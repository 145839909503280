import styled from "styled-components";
import { device } from "../utils/sizes";
import posed from "react-pose";

const AnimatedDiv = posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: 50, opacity: 0 },
  transition: { type: "spring", stiffness: 50 }
});

export const StreakLabel = styled.span`
  font-weight: 600;
  font-family: Poppins;
  position: absolute;
  color: ${props => props.color};

  @media ${device.mobileS} {
    font-size: 0.75rem;
    right: -0.25rem;
    top: -0.25rem;
  }

  @media ${device.mobileM} {
    font-size: 0.75rem;
    right: -0.25rem;
    top: -0.25rem;
  }
  @media ${device.mobileL} {
    font-size: 1rem;
    right: -0.5rem;
    top: -0.5rem;
  }

  @media ${device.tablet} {
    font-size: 1.25rem;
    right: -0.75rem;
    top: -0.75rem;
  }
  @media ${device.laptop} {
    font-size: 1.5rem;
    right: -0.75rem;
    top: -0.75rem;
  }
`;

export const HabitIcon = styled.i<{ isStreak: boolean }>`
  position: relative;
  color: ${props => props.color};
  @media ${device.mobileS} {
    font-size: 1rem;
  }
  @media ${device.mobileM} {
    font-size: 1.5rem;
  }
  @media ${device.tablet} {
    font-size: 2rem;
  }
  @media ${device.laptop} {
    font-size: 2.5rem;
  }
  @media ${device.laptopL} {
    font-size: 3rem;
  }

  ${props =>
    props.isStreak &&
    `::after {
    content: "";
    background: currentColor;
    @media ${device.mobileS} {
      height: calc(100% + 1rem + 1px);
    }
    @media ${device.mobileM} {
      height: calc(100% + 1rem + 1px);
    }
    @media ${device.mobileL} {
      height: calc(100% + 1rem + 1px);
    }
    @media ${device.tablet} {
      height: calc(100% + 1rem + 1px);
    }
    @media ${device.laptopL} {
      height: calc(100% + 1rem + 1px);
    }
    z-index: -10;
    right: calc(50% - 3.75px);
    transform: translateY(50%);
    bottom: 50%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 7.5px;
    opacity: 0.2;
  }
  `}
`;

export const CountTotalContainer = styled.div``;

export const CountTotalCompleted = styled.span`
  font-weight: 500;
  color: ${props => props.color};
  @media ${device.mobileS} {
    font-size: 1rem;
  }
  @media ${device.mobileM} {
    font-size: 1rem;
  }
  @media ${device.mobileL} {
    font-size: 1.25rem;
  }
  @media ${device.tablet} {
    font-size: 1.5rem;
  }
  @media ${device.laptop} {
    font-size: 2rem;
  }
  @media ${device.laptopL} {
    font-size: 2.5rem;
  }
`;
export const CountTotalTotal = styled.span`
  font-weight: 200;
  color: gray;
  @media ${device.mobileS} {
    font-size: 0.5rem;
  }
  @media ${device.mobileM} {
    font-size: 0.5rem;
  }
  @media ${device.mobileL} {
    font-size: 0.75rem;
  }
  @media ${device.tablet} {
    font-size: 1rem;
  }
  @media ${device.laptop} {
    font-size: 1rem;
  }
  @media ${device.laptopL} {
    font-size: 1.5rem;
  }
`;

export const TotalsLabel = styled.span`
  color: gray;
  text-align: left;
  width: 10%;
  @media ${device.mobileS} {
    font-size: 0.5rem;
  }
  @media ${device.mobileM} {
    font-size: 0.5rem;
  }
  @media ${device.mobileL} {
    font-size: 0.75rem;
  }
  @media ${device.tablet} {
    font-size: 1rem;
  }
  @media ${device.laptop} {
    font-size: 1rem;
  }
  @media ${device.laptopL} {
    font-size: 1.5rem;
  }
`;

export const DayAndDate = styled.div`
  width: 10%;
  display: flex;
  align-items: center;
  @media ${device.mobileS} {
    font-size: 0.5rem;
  }
  @media ${device.mobileM} {
    font-size: 0.5rem;
  }
  @media ${device.mobileL} {
    font-size: 0.75rem;
  }
  @media ${device.tablet} {
    font-size: 1rem;
  }
  @media ${device.laptop} {
    font-size: 1rem;
  }
  @media ${device.laptopL} {
    font-size: 1.5rem;
  }
`;

export const HabitSummaryRow = styled.div<{ opacity: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  @media ${device.mobileS} {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  @media ${device.mobileM} {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  @media ${device.mobileL} {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  @media ${device.tablet} {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  @media ${device.laptop} {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  @media ${device.laptopL} {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  opacity: ${props => props.opacity};
`;

export const IndividualDayRow = styled(AnimatedDiv)`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const HabitCell = styled.div<{ backgroundColor?: string }>`
  position: relative;
  background-color: ${props => props.backgroundColor};
  @media ${device.mobileS} {
    min-width: 1.5rem;
    width: 1.5rem;
    max-width: 1.5rem;
  }
  @media ${device.mobileM} {
    min-width: 2rem;
    width: 2rem;
    max-width: 2rem;
  }
  @media ${device.tablet} {
    width: 1.5rem;
    min-width: 2.5rem;
    width: 2.5rem;
    max-width: 2.5rem;
  }
  @media ${device.laptop} {
    width: 3rem;
    min-width: 3rem;
  }
  @media ${device.laptopL} {
    width: 3.5rem;
    min-width: 3.5rem;
  }
`;

export const Summary = styled.p`
  align-self: flex-start;
  justify-self: flex-start;
  margin-right: auto;
  margin-left: 1rem;
  margin-bottom: 0;
  @media ${device.mobileS} {
    font-size: ".75rem";
  }
  @media ${device.mobileL} {
    font-size: "1rem";
  }
`;

export const WeekSummaryMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const WeekSummaryTopPortionDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
`;
