import React, { useState, useRef } from "react";
import posed, { PoseGroup } from "react-pose";
import Color from "color";
import {
  HabitDay,
  HabitData,
  HabitKey,
  HABIT_CONFIG
} from "../utils/dataGenerator";
import { Switch, FormControlLabel } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import _moment from "moment";
import { Moment } from "moment";
import { extendMoment } from "moment-range";
import { useSelector } from "react-redux";
import {
  HabitTotals,
  calculateHabitStreakData,
  calculateHabitTotals,
  getStartAndEndOfDataRange,
  extractDataToRenderForRange,
  extractHabitsFromHabitDay,
  sortHabits
} from "./WeekSummaryUtils";
import {
  HabitCell,
  StreakLabel,
  HabitIcon,
  HabitSummaryRow,
  Summary,
  WeekSummaryTopPortionDiv,
  WeekSummaryMainDiv,
  CountTotalCompleted,
  CountTotalContainer,
  CountTotalTotal,
  TotalsLabel,
  DayAndDate,
  IndividualDayRow
} from "./WeekSummaryStyledComponents";

//@ts-ignore
const moment = extendMoment(_moment);

const AnimatedDiv = posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: 50, opacity: 0 },
  transition: { type: "spring", stiffness: 50 }
});

interface WeekSummaryProps {
  dateRangeType: "week" | "month" | "year";
}

const WeekSummary: React.FC<WeekSummaryProps> = ({ dateRangeType }) => {
  const matches = useMediaQuery("(max-width:480px)");
  const [contentMode, toggleContentMode] = useState("habits");

  const data = useSelector<HabitData, HabitData>(state => state);
  const dataToRender = extractDataToRenderForRange(data, dateRangeType);
  const habitDataToVisualize = calculateHabitStreakData(dataToRender);
  const totals = calculateHabitTotals(dataToRender);

  const habitDataForRangeAndTotals = [totals, ...habitDataToVisualize];

  const todayRowRef = useRef<HTMLDivElement>(null);

  // React.useEffect(() => {
  //   todayRowRef && todayRowRef.current && todayRowRef.current.scrollIntoViewIfNeeded({ behavior: "smooth", block: "nearest", inline: "start" });
  // }, []);

  //  RENDERER HELPERS  //
  const renderCountTotal = (habit: HabitKey, completedCount: number) => {
    const fancyFullRangeOfHabitsCompletedIcon = (
      <div
        className="fw-700"
        style={{
          fontSize: matches ? "1.5rem" : "2rem",
          minHeight: matches ? "1.75rem" : "3rem",
          color: HABIT_CONFIG[habit].color,
          borderRadius: matches ? ".875rem" : "1.5rem",
          backgroundColor: Color(HABIT_CONFIG[habit].color)
            .fade(0.8)
            .toString()
        }}
      >
        <p className="mb-0 fw-700">{completedCount}</p>
      </div>
    );
    return (completedCount === 7 && dateRangeType === "week") ||
      (completedCount >= 30 && dateRangeType === "month") ? (
      fancyFullRangeOfHabitsCompletedIcon
    ) : (
      <CountTotalContainer>
        <CountTotalCompleted color={HABIT_CONFIG[habit].color}>
          {" "}
          {completedCount}
        </CountTotalCompleted>
        <CountTotalTotal>
          /
          {dateRangeType === "month"
            ? moment().daysInMonth()
            : dateRangeType === "year"
            ? 365
            : 7}
        </CountTotalTotal>
      </CountTotalContainer>
    );
  };

  const renderSummaryToggleAtTop = () => (
    //#region
    <FormControlLabel
      control={
        <Switch
          checked={contentMode === "summary"}
          onChange={() =>
            toggleContentMode(contentMode === "summary" ? "habits" : "summary")
          }
          value="checkedB"
          color="secondary"
        />
      }
      label={
        <p
          className="mb-0 fw-200"
          style={{
            fontSize: matches ? ".75rem" : "1.5rem"
          }}
        >
          Summaries
        </p>
      }
    />
  );
  //#endregion

  const renderDateRangeAtTop = ([startOfDateRange, endOfDateRange]: [
    Moment,
    Moment
  ]) => (
    //#region
    <h2
      className="mr-3 fw-200"
      style={{
        fontSize: matches ? "1rem" : "1.5rem"
      }}
    >
      {startOfDateRange.format("MMM ")}
      <b>{startOfDateRange.format("D")} </b>- {endOfDateRange.format("MMM ")}
      {<b>{endOfDateRange.format("D")} </b>}
    </h2>
  );
  //#endregion

  const renderSummaryTotals = (habitDayTotals: HabitTotals) => {
    return (
      contentMode !== "summary" && (
        <AnimatedDiv
          className="d-flex flex-row align-items-center justify-content-between mb-4"
          key={"totals-row"}
        >
          <TotalsLabel>totals</TotalsLabel>
          {(Object.keys(habitDayTotals) as HabitKey[])
            .sort(sortHabits)
            .map(habitName => {
              return (
                <HabitCell key={habitName}>
                  {renderCountTotal(
                    habitName as HabitKey,
                    habitDayTotals[habitName as HabitKey]
                  )}
                </HabitCell>
              );
            })}
        </AnimatedDiv>
      )
    );
  };

  const renderDateAndDayOfWeekColumnOfRow = (habitDay: HabitDay) => {
    const todaysDate = moment()
      .startOf("day")
      .isSame(habitDay.date);
    const color = todaysDate ? "#6dd400" : "gray";
    const fontWeight = todaysDate ? 700 : 200;
    return (
      <DayAndDate>
        <p style={{ color, fontWeight }} className={`mr-1 mb-0 `}>
          {moment(habitDay.date).format("D")}
        </p>
        <p style={{ color, fontWeight }} className={`mb-0`}>
          {moment(habitDay.date).format("dd")}
        </p>
      </DayAndDate>
    );
  };

  const renderHabitCell = ({
    habit,
    habitDay
  }: {
    habit: HabitKey;
    habitDay: HabitDay;
  }) => {
    let habitType = "";
    switch (true) {
      case habitDay[habit] === false:
        habitType = "not-acheived";
        break;
      case habitDay[habit] === true:
        habitType = "acheived";
        break;
      case habitDay[habit] === 1:
        habitType = "partOfStreak";
        break;
      case habitDay[habit] >= 1:
        habitType = "startOfStreak";
        break;
    }
    // const allHabitsForTodayMet = Object.values(habitDay).every(val => val);

    // const habitCellBackgroundColor = allHabitsForTodayMet
    //   ? Color(HABIT_CONFIG[habit].color)
    //       .fade(0.9)
    //       .toString()
    //   : "transparent";

    const habitIconColor =
      habitType === "not-acheived"
        ? "rgba(0,0,0,.2)"
        : HABIT_CONFIG[habit].color;
    const habitIconClassName = ` ${HABIT_CONFIG[habit].icon} ${
      habitType === "partOfStreak" || habitType === "startOfStreak"
        ? "habit-icon-streak"
        : ""
    }
    `;

    return (
      <HabitCell
        key={`${habit}:${habitDay.date.toString()}`}
        backgroundColor={"transparent"}
      >
        {habitType === "startOfStreak" && (
          <StreakLabel color={HABIT_CONFIG[habit].color}>
            {habitDay[habit]}
          </StreakLabel>
        )}
        <HabitIcon
          isStreak={
            habitType === "partOfStreak" || habitType === "startOfStreak"
          }
          color={habitIconColor}
          className={habitIconClassName}
        />
      </HabitCell>
    );
  };

  const renderIndividualRow = (habitDay: HabitDay) => (
    <IndividualDayRow
      data-testid="animated-individual-day-row"
      key={habitDay.date.toString()}
      ref={
        moment()
          .startOf("day")
          .isSame(habitDay.date)
          ? todayRowRef
          : null
      }
    >
      <HabitSummaryRow
        data-testid="habit-or-summary-row"
        opacity={moment(habitDay.date).isAfter(moment()) ? 0.25 : 1}
      >
        {renderDateAndDayOfWeekColumnOfRow(habitDay)}
        {contentMode === "summary" ? (
          <Summary>{habitDay.summary}</Summary>
        ) : (
          extractHabitsFromHabitDay(habitDay).map(habit =>
            renderHabitCell({ habit, habitDay })
          )
        )}
      </HabitSummaryRow>
      <hr
        style={{
          width: "100%",
          height: moment(habitDay.date).day() === 6 ? "3px" : "1px"
        }}
        className="mb-0 mt-0"
      />
      {Object.values(habitDay).every(val => val) &&
        contentMode !== "summary" && (
          <i
            style={{ fontSize: matches ? ".75rem" : "1.5rem" }}
            className="fas fa-star floating-star"
          />
        )}
    </IndividualDayRow>
  );

  const renderNewRowsOfData = () => {
    return habitDataForRangeAndTotals.map((habitDayUnknown, index) => {
      // RENDER LAST ROW AS TOTALS
      if (index === 0)
        return renderSummaryTotals(habitDayUnknown as HabitTotals);

      const habitDay = habitDayUnknown as HabitDay;
      // get just the keys off the Habit Day that will have icons rendered
      return renderIndividualRow(habitDay);
    });
  };

  //  MAIN RENDER  //
  return (
    <WeekSummaryMainDiv data-testid="week-summary-main-div">
      <WeekSummaryTopPortionDiv>
        {renderSummaryToggleAtTop()}
        {renderDateRangeAtTop(getStartAndEndOfDataRange(dateRangeType))}
      </WeekSummaryTopPortionDiv>
      <div>
        <PoseGroup>{renderNewRowsOfData()}</PoseGroup>
      </div>
    </WeekSummaryMainDiv>
  );
};
export default WeekSummary;
