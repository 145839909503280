import styled from "styled-components";
import { device } from "./utils/sizes";
export const AppDiv = styled.div`
  @media ${device.mobileS} {
    margin: 20px 5%;
  }

  @media ${device.mobileM} {
    margin: 20px 5%;
  }

  @media ${device.tablet} {
    margin: 20px 10%;
  }
  @media ${device.laptop} {
    margin: 20px 20%;
  }
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 100vh;
  height: 100%;
`;
