import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import MainSquare from "./components/MainSquare";
import WeekSummary from "./components/WeekSummary";
import "./App.css";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from "@material-ui/core";

import posed, { PoseGroup } from "react-pose";
import { useSelector } from "react-redux";
import {
  HabitData,
  HabitDay,
  emptyHabitDay,
  HABIT_CONFIG
} from "./utils/dataGenerator";
import moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { AppDiv } from "./AppStyledComponents";
import { setFullData } from "./reducers";
import IntroModal from "./IntroModal";
import { HSLogo } from "./HSLogo";


const App: React.FC = () => {
  const [mode, updateMode] = useState<"today" | "week" | "month" | "year">(
    "today"
  );
  const matches = useMediaQuery("(max-width:480px)");

  const [introModalIsOpen, setIntroModalIsOpen] = React.useState(false);
  const [exportImportModalIsOpen, setExportImportModalIsOpen] = React.useState(
    false
  );
  const [openViewHabits, setViewHabitsOpen] = React.useState(false);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [copySuccess, setCopySuccess] = useState("");
  const [newData, setNewData] = useState("");
  const [importError, setImportError] = useState(false);
  // Detects if device is on iOS

  const dispatch = useDispatch();

  function handleClickOpenViewHabits() {
    setViewHabitsOpen(true);
  }

  function handleCloseViewHabits() {
    setViewHabitsOpen(false);
  }

  function openIntroModal() {
    setIntroModalIsOpen(true);
  }
  function closeIntroModal() {
    setIntroModalIsOpen(false);
  }

  function openExportImportModal() {
    setExportImportModalIsOpen(true);
  }
  function closeExportImportModal() {
    setExportImportModalIsOpen(false);
  }

  const allData = useSelector<HabitData, HabitData>(state => state);
  const today = useSelector<HabitData, HabitDay>(
    state =>
      state[
        moment()
          .startOf("day")
          .toISOString()
      ] ||
      emptyHabitDay(
        moment()
          .startOf("day")
          .toDate()
      )
  );

  React.useEffect(() => {
    // allData will only have more than 1 key if the user has already entered habit data (and therefore don't need the intro)
    // otherwise, we assume the user has never entered data and needs the intro modal
    Object.keys(allData).length <= 1 && openIntroModal();
  }, [allData]);
  const AnimatedDiv = posed.div({
    enter: { y: 0, opacity: 1 },
    exit: { y: 50, opacity: 0 },
    transition: {
      type: "physics",
      velocity: 1000
    }
  });
  const iconSizeMobile = "2.0rem";
  const iconSizeDesktop = "3.0rem";

  const iconWidthMobile = "2.25rem";
  const iconWidthDesktop = "3.25rem";

  const renderHabitLookupModal = () => {
    return (
      <Dialog
        maxWidth={"lg"}
        open={openViewHabits}
        onClose={handleCloseViewHabits}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle
          className="d-flex flex-row align-items-center justify-content-center"
          id="max-width-dialog-title"
        >
          <div className="d-flex flex-row align-items-center">
            <p className="m-0 mr-2">Habit Legend</p>
            <i className="far fa-search" />
          </div>
        </DialogTitle>
        <DialogContent className="d-flex flex-column align-items-center justify-content-center">
          <div>
            <div className="d-flex flex-column  flex-row align-items-start">
              {Object.entries(HABIT_CONFIG).map(entry => {
                const [habitKey, habitObj] = entry;
                return (
                  <div
                    className="d-flex flex-row align-items-center"
                    key={habitKey}
                  >
                    <i
                      style={{
                        fontSize: matches ? iconSizeMobile : iconSizeDesktop,
                        minWidth: matches ? iconWidthMobile : iconWidthDesktop,
                        width: matches ? iconWidthMobile : iconWidthDesktop,

                        color: habitObj.color
                      }}
                      className={`${habitObj.icon} habit-icon icon-walkthrough-modal mr-3`}
                    />
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: 400,
                        whiteSpace: "nowrap",
                        color: "black",
                        fontSize: matches ? "1rem" : "1.25rem"
                      }}
                    >
                      = {habitObj.description}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCloseViewHabits();
              openExportImportModal();
            }}
            color="primary"
          >
            Export / Import
          </Button>
          <Button onClick={handleCloseViewHabits} color="primary">
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  function copyToClipboard(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (!textAreaRef.current) {
      return;
    }
    const range = document.createRange();
    textAreaRef.current && textAreaRef.current.select();

    textAreaRef.current.readOnly = false;
    range.selectNodeContents(textAreaRef.current);

    var s = window.getSelection();
    s && s.removeAllRanges();
    s && s.addRange(range);

    textAreaRef.current.setSelectionRange(0, 999999); // A b

    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    setCopySuccess("Copied!");
  }

  function persistNewData(data: string) {
    let importedData: HabitData = {};
    try {
      importedData = JSON.parse(data);
      setImportError(false);
    } catch {
      setImportError(true);
      return false;
    }

    dispatch(setFullData(importedData));
  }

  const renderExportImportModal = () => {
    return (
      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={exportImportModalIsOpen}
        onClose={closeExportImportModal}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle
          className="d-flex flex-row align-items-center justify-content-center"
          id="max-width-dialog-title"
        >
          <div className="d-flex flex-row align-items-center">
            <p className="m-0 mr-2">Export / Import Data</p>
            <i className="fas fa-flask" />
          </div>
        </DialogTitle>
        <DialogContent className="d-flex flex-column align-items-center justify-content-center">
          <div className="d-flex flex-row align-items-center mb-1">
            <DialogContentText className="mb-0">
              Current Data from your local storage
            </DialogContentText>
            <Button
              color="primary"
              variant={"contained"}
              className="mx-1"
              size="small"
              onClick={copyToClipboard}
            >
              Copy
            </Button>
            <span style={{ color: "#3f51b5" }}>{copySuccess}</span>
          </div>

          <textarea
            ref={textAreaRef}
            contentEditable={false}
            value={JSON.stringify(allData)}
            readOnly={false}
            // disabled={true}
            style={{
              fontSize: ".5rem",
              margin: "0 20%",
              width: "100%",
              overflowY: "scroll",
              height: "20vh"
            }}
          ></textarea>
        </DialogContent>
        <DialogContent className="d-flex flex-column align-items-center justify-content-center">
          <div className="d-flex flex-row align-items-center mb-1">
            <DialogContentText className="mb-0">
              Replace all data with:
              <i
                style={{ color: "#3f51b5" }}
                className="fas fa-exclamation-triangle mx-1"
              />
            </DialogContentText>
            <Button
              color="primary"
              variant={"contained"}
              className="mx-1"
              size="small"
              disabled={!newData}
              onClick={() => persistNewData(newData)}
            >
              Replace
            </Button>
            <span style={{ color: "#3f51b5", fontSize: ".5rem" }}>
              {importError ? "Data imported was in bad shape" : ""}
            </span>
          </div>

          <textarea
            value={newData}
            onChange={e => setNewData(e.target.value)}
            style={{
              margin: "0 20%",
              width: "100%",
              overflowY: "scroll",
              fontSize: ".5rem",
              height: "20vh"
            }}
          ></textarea>
        </DialogContent>
        <DialogActions className="d-flex flex-row align-items-center justify-content-between mx-3">
          <Button onClick={closeExportImportModal} color="secondary">
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <AppDiv>
      <IntroModal
        isIntroModalOpen={introModalIsOpen}
        closeIntroModal={closeIntroModal}
        handleClickOpenViewHabits={handleClickOpenViewHabits}
      />
      {renderHabitLookupModal()}
      {renderExportImportModal()}
      <div className="d-flex flex-row align-items-center">
        <HSLogo width={160} height={70} />
        <Button
          color="primary"
          style={{
            fontSize: matches ? "1rem" : "1.5rem",
            position: "absolute",
            left: 0,
            top: "1rem",
            color: "black"
            // background: "transparent"
          }}
          onClick={openIntroModal}
          className="icon-walkthrough-modal d-flex flex-row align-items-center"
        >
          <i className="far fa-info-circle mr-1" />
          <p style={{ fontWeight: 700 }} className="mb-0">
            About
          </p>
        </Button>
        <Button
          color="secondary"
          style={{
            fontSize: matches ? "1rem" : "1.5rem",
            position: "absolute",
            right: 0,
            top: "1rem",
            color: "black"
          }}
          onClick={handleClickOpenViewHabits}
          className="icon-walkthrough-modal  d-flex flex-row align-items-center"
        >
          <i className="far fa-search mr-1" />
          <p style={{ fontWeight: 700 }} className="mb-0">
            Legend
          </p>
        </Button>
      </div>

      <div
        style={{ position: "relative", marginBottom: "1rem" }}
        className="flex-row align-items-center "
      >
        <Button
          color="inherit"
          variant={mode === "today" ? "contained" : "outlined"}
          style={{
            color: "black",
            borderRadius: 20
          }}
          className="mx-1"
          onClick={() => updateMode("today")}
        >
          Today
        </Button>
        <Button
          color="inherit"
          variant={mode === "week" ? "contained" : "outlined"}
          className="mx-1"
          style={{
            color: "black",
            borderRadius: 20
          }}
          onClick={() => updateMode("week")}
        >
          Week
        </Button>
        {(mode === "week" || mode === "month" || mode === "year") && (
          <Button
            color="primary"
            style={{
              position: "absolute"
            }}
            variant={mode === "month" ? "contained" : "outlined"}
            className="mx-1"
            onClick={() => updateMode("month")}
          >
            Month
          </Button>
        )}
      </div>

      <PoseGroup>
        {mode === "today" ? (
          <AnimatedDiv key="main-square">
            <MainSquare todayFromRedux={today} />
          </AnimatedDiv>
        ) : (
          <AnimatedDiv
            style={{ width: "100%" }}
            name="animated-week-summary-wrapper"
            key="week-summarry"
          >
            <WeekSummary dateRangeType={mode} />
          </AnimatedDiv>
        )}
      </PoseGroup>
    </AppDiv>
  );
};

export default App;
