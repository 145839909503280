import { createStore } from "redux";
import habitSquareReducer from "./reducers";
import { persistStore, persistReducer, createMigrate } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { HabitData, OldHabitData, HabitDay } from "./utils/dataGenerator";

const migrations = {
  "-1": (state: any) => {
    console.log("migration from -1");
    console.log(state);
    return state;
  },
  0: (state: any) => {
    if (state._persist.version === -1) {
      const oldState: OldHabitData = state;
      const newState: HabitData = {};
      Object.entries(oldState).map(([date, habitDay]) => {
        const newHabit: HabitDay = {
          habit_one: habitDay.read,
          habit_two: habitDay.meditated,
          habit_three: habitDay.core,
          habit_four: habitDay.ateClean,
          habit_five: habitDay.workedOut,
          habit_six: habitDay.sleptWell,
          habit_seven: Number(habitDay.spentLittle),
          habit_eight: habitDay.playedMusic,
          summary: habitDay.summary,
          date: habitDay.date
        };
        newState[date] = newHabit;
        return true;
      });
      return newState;
    } else {
      // this is going to be either a downgrade, or a same version in which case no migration
      return state;
    }
  },
  1: (state: any) => {
    if (state._persist.version === -1) {
      const oldState: OldHabitData = state;
      const newState: HabitData = {};
      Object.entries(oldState).map(([date, habitDay]) => {
        const newHabit: HabitDay = {
          habit_one: Boolean(habitDay.read),
          habit_two: Boolean(habitDay.meditated),
          habit_three: Boolean(habitDay.core),
          habit_four: Boolean(habitDay.ateClean),
          habit_five: Boolean(habitDay.workedOut),
          habit_six: Boolean(habitDay.sleptWell),
          habit_seven: !Boolean(Number(habitDay.spentLittle)),
          habit_eight: Boolean(habitDay.playedMusic),
          summary: habitDay.summary,
          date: habitDay.date
        };
        newState[date] = newHabit;
        return true;
      });
      return newState;
    } else {
      const oldState: HabitData = state;
      const newState: HabitData = {};
      Object.entries(oldState).map(([date, habitDay]) => {
        const newHabit: HabitDay = {
          habit_one: Boolean(habitDay.habit_one),
          habit_two: Boolean(habitDay.habit_two),
          habit_three: Boolean(habitDay.habit_three),
          habit_four: Boolean(habitDay.habit_four),
          habit_five: Boolean(habitDay.habit_five),
          habit_six: Boolean(habitDay.habit_six),
          habit_seven: !Boolean(habitDay.habit_seven),
          habit_eight: Boolean(habitDay.habit_eight),
          summary: habitDay.summary,
          date: habitDay.date
        };
        newState[date] = newHabit;
        return true;
      });
      return newState;
    }
  }
};

const persistConfig = {
  key: "root",
  storage,
  version: 1,
  migrate: createMigrate(migrations, { debug: true })
};

const persistedReducer = persistReducer(persistConfig, habitSquareReducer);

export const store = createStore(
  persistedReducer,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);
export const persistor = persistStore(store);
