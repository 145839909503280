import styled from "styled-components";
import { device } from "../utils/sizes";

interface MainSquareProps {}

export const MainSquareDiv = styled.div<MainSquareProps>`
  position: relative;
  border: 2px solid #505050;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(211, 211, 211, 0.5);
  padding: 0.5rem;
  @media ${device.mobileS} {
    width: 175px;
    height: 175px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-radius: 20px;
  }

  @media ${device.mobileM} {
    width: 200px;
    height: 200px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-radius: 20px;
  }

  @media ${device.tablet} {
    width: 400px;
    height: 400px;
    margin-top: 4rem;
    margin-bottom: 4rem;
    border-radius: 40px;
  }
`;

export default MainSquareDiv;
