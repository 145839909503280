import _moment from "moment";
import { Moment } from "moment";
import { extendMoment } from "moment-range";
import {
  HabitDay,
  HabitKey,
  HABIT_CONFIG,
  HabitData,
  emptyHabitDay
} from "../utils/dataGenerator";

// @ts-ignore
const moment = extendMoment(_moment);

export type HabitTotals = { [index in HabitKey]: number };

export const extractHabitsFromHabitDay = (habitDay: HabitDay): HabitKey[] =>
  (Object.keys(habitDay).filter(
    habit => habit !== "summary" && habit !== "date"
  ) as HabitKey[]).sort(sortHabits);

// Extremely stupid solution to sorting Habits within a day (used for the row on the WeekScreen)
// since the default sorting (which does a alphabet compare) won't work since I was stupid
// and keyed the habits using habit_one instead of habit_1. Rather than doing another
// Redux persist data migration to move existing data from habit_one to habit_1, just have mapping
export const sortHabits = (habitOne: HabitKey, habitTwo: HabitKey) => {
  const habitNameToOrderMapping: { [index in HabitKey]: number } = {
    habit_one: 1,
    habit_two: 2,
    habit_three: 3,
    habit_four: 4,
    habit_five: 5,
    habit_six: 6,
    habit_seven: 7,
    habit_eight: 8
  };
  return habitNameToOrderMapping[habitOne] > habitNameToOrderMapping[habitTwo]
    ? 1
    : -1;
};

export const calculateHabitTotals = (dataToRender: HabitDay[]) => {
  const habitTotalsObject: HabitTotals = {
    habit_one: 0,
    habit_two: 0,
    habit_three: 0,
    habit_four: 0,
    habit_five: 0,
    habit_six: 0,
    habit_seven: 0,
    habit_eight: 0
  };
  const totals = dataToRender.reduce((acc, habitDay) => {
    acc.habit_one += habitDay.habit_one ? 1 : 0;
    acc.habit_two += habitDay.habit_two ? 1 : 0;
    acc.habit_three += habitDay.habit_three ? 1 : 0;
    acc.habit_four += habitDay.habit_four ? 1 : 0;
    acc.habit_five += habitDay.habit_five ? 1 : 0;
    acc.habit_six += habitDay.habit_six ? 1 : 0;
    acc.habit_seven += habitDay.habit_seven ? 1 : 0;
    acc.habit_eight += habitDay.habit_eight ? 1 : 0;
    return acc;
  }, habitTotalsObject);
  return totals;
};

export const calculateHabitStreakData = (dataToRender: HabitDay[]) => {
  const habitDataToVisualize: any = dataToRender;
  Object.keys(HABIT_CONFIG).map(habitRaw => {
    let index = 0;
    const habit = habitRaw as HabitKey;
    while (index < dataToRender.length - 2) {
      // no chance of streak starting if we’re within last 2 days of
      const currentDay = dataToRender[index]; // starting from this currentDay, how big of a streak do we have?
      const secondDay = dataToRender[index + 1];
      const thirdDay = dataToRender[index + 2];
      const potentialStreak =
        currentDay[habit] && secondDay[habit] && thirdDay[habit];
      if (!potentialStreak) {
        index++;
        continue; // don’t have 3 days in a row, slide down window and try again moved one day down
      }
      habitDataToVisualize[index][habit] = 1;
      habitDataToVisualize[index + 1][habit] = 1;
      habitDataToVisualize[index + 2][habit] = 1;
      const extendedWindowSize = 3;
      let currentStreakCounter = 3;
      let nextDayIndex = index + extendedWindowSize;
      while (nextDayIndex < dataToRender.length) {
        // stay within the bounds of our date range
        const nextDayHasHabit = dataToRender[nextDayIndex][habit];
        if (nextDayHasHabit) {
          // streak stays alive, increase window size, increment counter, mark newDataToRender
          habitDataToVisualize[nextDayIndex][habit] = 1;
          currentStreakCounter++;
          nextDayIndex++;
        } else break;
      }
      habitDataToVisualize[nextDayIndex - 1][habit] = currentStreakCounter; // index is still going to be pointing to the beginning of the window, mark streak length
      index = nextDayIndex + 1; //  reset index (the window) to start right after the habit that killed the streak
    }
    return true;
  });
  return habitDataToVisualize;
};

export const getStartAndEndOfDataRange = (
  rangeType: "week" | "month" | "year",
  preseededDate?: Moment
): [Moment, Moment] => [
  preseededDate
    ? moment(preseededDate)
        .startOf(rangeType)
        .startOf("day")
    : moment()
        .startOf(rangeType)
        .startOf("day"),

  preseededDate
    ? moment(preseededDate)
        .endOf(rangeType)
        .startOf("day")
    : moment()
        .endOf(rangeType)
        .startOf("day")
];

export const extractDataToRenderForRange = (
  data: HabitData,
  rangeType: "week" | "month" | "year",
  preseededDate?: Moment
) => {
  const [startOfDateRange, endOfDateRange] = getStartAndEndOfDataRange(
    rangeType,
    preseededDate
  );

  const range = moment.range(startOfDateRange, endOfDateRange);

  // For each "day" in our range of what is going to be rendered, either:
  //  a.) lookup and return the HabitDay from our big 'data' object corresponding to the correct day
  //  b.) or return an empty Habit day. Our Redux data might be sparse if user forgot to log
  //      so simply return empty day
  const dataToRender = Array.from(range.by("day")).map(
    day =>
      data[day.startOf("day").toISOString()] ||
      emptyHabitDay(day.startOf("day").toDate())
  );
  return dataToRender;
};
