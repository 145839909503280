export const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px"
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
};

export const RED_PINK = "#E00074";
export const FIRE_ORANGE = "#ff2b2b";
export const SOAP_BLUE = "#01dacc";
export const MOSS_GREEN = "#00E06C";
export const SIMPLE_BLUE = "#1C8BFF";
export const STRAIGHT_PURPLE = "#853ef7";
export const BOLD_YELLOW = "#ffb400";
export const SUBTLE_RED = "#FF2BFD";
