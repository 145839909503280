import React, { useState } from "react";
import { PoseGroup } from "react-pose";

import { TextField, Button } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment";
import { HabitDay, emptyHabitDay, HabitKey } from "../utils/dataGenerator";
import { setHabitDay } from "../reducers";
import { useDispatch } from "react-redux";
import MainSquareDiv from "./MainSquareDiv";
import { TappableHabitIcon } from "./TappableHabitIcon";

const MainSquare: React.FC<{
  todayFromRedux: HabitDay;
}> = ({ todayFromRedux }) => {
  const matches = useMediaQuery("(max-width:480px)");
  const dispatch = useDispatch();
  const [today, handleHabitChanges] = useState(
    emptyHabitDay(
      moment()
        .startOf("day")
        .toDate()
    )
  );
  const { summary } = today;

  React.useEffect(() => {
    handleHabitChanges(value => todayFromRedux);
  }, [todayFromRedux]);

  const renderHabitsCompletedMessage = () => (
    //#region
    <div className="fw-200 mt-5 mb-0 d-flex flex-column align-items-start justify-content-start">
      <p
        style={{
          fontSize: matches ? "1.25rem" : "1.5rem"
        }}
      >
        <span
          className="fw-500"
          style={{
            color:
              JSON.stringify(today) === JSON.stringify(todayFromRedux)
                ? "black"
                : "gray"
          }}
        >
          {
            Object.keys(today)
              .filter(key => key !== "summary" && key !== "date")
              .map(key => today[key as HabitKey])
              .filter(habit => !!habit).length
          }{" "}
          out of 8
        </span>{" "}
        Habits Completed
      </p>
    </div>
  );
  //#endregion

  const renderHabitsAroundSquare = () => (
    //#region
    <PoseGroup>
      <TappableHabitIcon
        handleHabitChange={handleHabitChanges}
        key="habit_one"
        habit="habit_one"
        today={today}
      />
      <TappableHabitIcon
        handleHabitChange={handleHabitChanges}
        key="habit_two"
        habit="habit_two"
        today={today}
      />
      <TappableHabitIcon
        handleHabitChange={handleHabitChanges}
        key="habit_three"
        habit="habit_three"
        today={today}
      />
      <TappableHabitIcon
        handleHabitChange={handleHabitChanges}
        key="habit_four"
        habit="habit_four"
        today={today}
      />
      <TappableHabitIcon
        handleHabitChange={handleHabitChanges}
        key="habit_five"
        habit="habit_five"
        today={today}
      />
      <TappableHabitIcon
        handleHabitChange={handleHabitChanges}
        key="habit_six"
        habit="habit_six"
        today={today}
      />
      <TappableHabitIcon
        handleHabitChange={handleHabitChanges}
        key="habit_seven"
        habit="habit_seven"
        today={today}
      />
      <TappableHabitIcon
        handleHabitChange={handleHabitChanges}
        key="habit_eight"
        habit="habit_eight"
        today={today}
      />
    </PoseGroup>
  );
  //#endregion

  const renderTodaysDate = () => (
    //#region
    <div className="mb-3 d-flex flex-row align-items-center">
      <p className="mb-0 fw-200 fs-md">
        {moment().format("MMM")}{" "}
        <span className="fw-500">{moment().format("DD,")} </span>
        {moment().format("YYYY")}
      </p>
    </div>
  );
  //#endregion

  const renderInputInsideSquare = () => (
    //#region
    <TextField
      placeholder="Something memorable that you did today..."
      multiline={true}
      onChange={event => {
        const summary = event.target.value;
        handleHabitChanges(value => ({
          ...value,
          summary
        }));
      }}
      value={summary}
      rows={2}
      inputProps={{
        style: {
          color: "rgb(121, 121, 121)",
          fontSize: matches ? "1rem" : "2rem",
          fontWeight: 400,
          lineHeight: 1.5,
          fontFamily: "Poppins, sans-serif"
        }
      }}
      rowsMax={6}
      className="habit-text"
    />
  );
  //#endregion

  const renderSubmitButton = () => (
    //#region
    <Button
      color="primary"
      variant={"contained"}
      className="mx-1"
      disabled={JSON.stringify(today) === JSON.stringify(todayFromRedux)}
      onClick={() => {
        dispatch(setHabitDay(today));
      }}
    >
      Submit
    </Button>
  );
  //#endregion

  return (
    <div className="d-flex flex-column justify-content-center align-items-center mt-1">
      {renderTodaysDate()} {/* <div><p></p></div> */}
      <MainSquareDiv>
        {renderInputInsideSquare()} {/* <TextField/> */}
        {renderHabitsAroundSquare()} {/* <PoseGroup></PoseGroup> */}
      </MainSquareDiv>
      {renderHabitsCompletedMessage()} {/* <div><p></span></p></div> */}
      {renderSubmitButton()} {/* <Button></Button> */}
    </div>
  );
};

export default React.memo(MainSquare);
