import {
  SOAP_BLUE,
  SUBTLE_RED,
  STRAIGHT_PURPLE,
  RED_PINK,
  FIRE_ORANGE,
  SIMPLE_BLUE,
  MOSS_GREEN,
  BOLD_YELLOW
} from "./sizes";

export interface _HabitDayHabits {
  habit_one: boolean | number;
  habit_two: boolean | number;
  habit_three: boolean | number;
  habit_four: boolean | number;
  habit_five: boolean | number;
  habit_six: boolean | number;
  habit_seven: boolean | number;
  habit_eight: boolean | number;
}

interface _HabitDaySummaryAndDate {
  summary: string;
  date: Date;
}

export interface OldHabitDay {
  [index: string]: any;
  read: boolean | number;
  meditated: number;
  core: boolean;
  ateClean: boolean;
  workedOut: boolean;
  sleptWell: number; // minutes
  spentLittle: string;
  playedMusic: boolean | number;
  summary: string;
  date: Date;
}

export type HabitDay = _HabitDayHabits & _HabitDaySummaryAndDate;

export type HabitKey = keyof _HabitDayHabits;

export type POSITIONS =
  | "top-right-right"
  | "top-right-top"
  | "bottom-right-right"
  | "bottom-right-bottom"
  | "bottom-left-bottom"
  | "bottom-left-left"
  | "top-left-left"
  | "top-left-top";
export type HabitConfig = {
  [index in HabitKey]: {
    color: string;
    icon: string;
    label: string;
    description: string;
    threshold?: number;
    position?: POSITIONS;
  };
};

export const emptyHabitDay = (date: Date): HabitDay => ({
  habit_one: false,
  habit_two: false,
  habit_three: false,
  habit_four: false,
  habit_five: false,
  habit_six: false,
  habit_seven: false,
  habit_eight: false,
  summary: "",
  date
});

export const HABIT_CONFIG: HabitConfig = {
  habit_one: {
    description: "Worked Out?",
    label: "1+ hr",
    icon: "fas fa-heartbeat",
    color: RED_PINK,
    position: "top-right-right"
  },
  habit_two: {
    description: "Ate clean?",
    label: "Ate clean",
    icon: "fas fa-utensils",
    color: FIRE_ORANGE,
    position: "bottom-left-left"
  },
  habit_three: {
    description: "Drank 3+ L Water?",
    label: "3+ liters",
    icon: "far fa-glass",
    color: SIMPLE_BLUE,
    position: "top-right-top"
  },
  habit_four: {
    description: "Spent $0?",
    label: "$0",
    icon: "fas fa-dollar-sign",
    color: MOSS_GREEN,
    position: "bottom-left-bottom"
  },
  habit_five: {
    description: "Learned 3 new things today",
    label: "3+ things learned",
    icon: "far fa-lightbulb-on",
    color: BOLD_YELLOW,
    position: "bottom-right-bottom"
  },
  habit_six: {
    description: "Meditated 15+ minutes?",
    label: "15+ min",
    icon: "fas fa-om",
    color: SUBTLE_RED,
    position: "top-left-top"
  },
  habit_seven: {
    description: "Slept 7+ hours?",
    label: "7+ hours ",
    icon: "far fa-snooze",
    color: SOAP_BLUE,
    position: "bottom-right-right"
  },
  habit_eight: {
    description: "Read for 30+ minutes?",
    label: "30+ min",
    icon: "fas fa-book",
    color: STRAIGHT_PURPLE,
    position: "top-left-left"
  }
};

export interface HabitDayThresholds {
  sleptWell: number;
}

export const THRESHOLDS: HabitDayThresholds = {
  sleptWell: 420
};

export interface OldHabitData {
  [index: string]: OldHabitDay;
}
export interface HabitData {
  [index: string]: HabitDay;
}
