import React, { useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";
import { HABIT_CONFIG } from "./utils/dataGenerator";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { HSLogo } from "./HSLogo";

const iconSizeMobile = "2.0rem";
const iconSizeDesktop = "3.0rem";

const iconWidthMobile = "2.25rem";
const iconWidthDesktop = "3.25rem";
export interface IntroModalProps {
  closeIntroModal: () => void;
  isIntroModalOpen: boolean;
  handleClickOpenViewHabits: () => void;
}

const IntroModal: React.FC<IntroModalProps> = ({
  closeIntroModal,
  isIntroModalOpen,
  handleClickOpenViewHabits
}) => {
  const startButtonRef = useRef<HTMLButtonElement>(null);
  const matches = useMediaQuery("(max-width:480px)");
  console.log("🔥 IntroModal is rendering");
  return (
    <Dialog
      maxWidth={"sm"}
      open={isIntroModalOpen}
      onClose={closeIntroModal}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle
        className="d-flex flex-row align-items-center justify-content-center"
        id="max-width-dialog-title"
      >
        <div className="d-flex flex-row align-items-center">
          <p className="m-0 mr-2">Welcome to </p>
          <HSLogo width={100} height={50} />
        </div>
      </DialogTitle>
      <DialogContent className="d-flex flex-column align-items-center justify-content-center">
        <DialogContentText>
          Mark habits as completed for the day by <b>tapping icons</b>.
        </DialogContentText>
        <div>
          <div className="d-flex flex-row align-items-center my-3">
            {Object.values(HABIT_CONFIG)
              .slice(0, 4)
              .map(habitObj => {
                return (
                  <i
                    key={habitObj.label}
                    onClick={() =>
                      startButtonRef.current && startButtonRef.current.focus()
                    }
                    style={{
                      minWidth: matches ? iconWidthMobile : iconWidthDesktop,
                      width: matches ? iconWidthMobile : iconWidthDesktop,
                      fontSize: matches ? iconSizeMobile : iconSizeDesktop,
                      color: habitObj.color
                    }}
                    className={`${habitObj.icon} habit-icon icon-walkthrough-modal mx-2`}
                  />
                );
              })}
          </div>
          <div className="d-flex flex-row align-items-center my-3">
            {Object.values(HABIT_CONFIG)
              .slice(4)
              .map(habitObj => {
                return (
                  <i
                    key={habitObj.label}
                    onClick={() =>
                      startButtonRef.current && startButtonRef.current.focus()
                    }
                    style={{
                      minWidth: matches ? iconWidthMobile : iconWidthDesktop,
                      width: matches ? iconWidthMobile : iconWidthDesktop,
                      fontSize: matches ? iconSizeMobile : iconSizeDesktop,
                      color: habitObj.color
                    }}
                    className={`${habitObj.icon} habit-icon icon-walkthrough-modal mx-2`}
                  />
                );
              })}
          </div>
        </div>

        <DialogContentText>
          Add <b>summaries</b> to record the most memorable from each day.
        </DialogContentText>
        <DialogContentText>
          Achieve habit streaks with <b>3+</b> consecutive days.
        </DialogContentText>
      </DialogContent>
      <DialogActions className="d-flex flex-row align-items-center justify-content-between mx-3">
        <Button
          onClick={() => {
            closeIntroModal();
            handleClickOpenViewHabits();
          }}
          color="secondary"
        >
          Habit Legend
        </Button>
        <Button
          className="active-intro-modal-button"
          ref={startButtonRef}
          onClick={closeIntroModal}
          color="primary"
        >
          Go to app
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(IntroModal);
