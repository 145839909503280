import React from "react";
import styled from "styled-components";
import { device } from "../utils/sizes";
import posed from "react-pose";

import {
  HabitDay,
  HABIT_CONFIG,
  HabitKey,
  POSITIONS
} from "../utils/dataGenerator";

export const TappableHabitIcon: React.FC<{
  habit: HabitKey;
  handleHabitChange: (value: any) => void;
  today: HabitDay;
}> = ({ habit, today, handleHabitChange }) => {
  const Habit = posed.div({
    pressable: true,
    hoverable: true,
    hover: { scale: 1.2 },
    init: { scale: 1 },
    press: { scale: 0.8, opacity: 0.2 },
    visible: { opacity: 1 },
    hidden: { opacity: 0.2 }
  });

  interface StyledHabitProps {
    position: POSITIONS;
    color: POSITIONS;
  }

  const calculateTopBottomLeftRight = (
    props: StyledHabitProps,
    spacingAmount: string
  ) => {
    switch (props.position) {
      case "top-right-right":
        return `top: 0;right: -${spacingAmount}`;
      case "top-left-left":
        return `top: 0;left: -${spacingAmount}`;
      case "top-left-top":
        return `left: 0;top: -${spacingAmount}`;
      case "bottom-right-bottom":
        return `right: 0;bottom: -${spacingAmount}`;
      case "bottom-left-bottom":
        return `left: 0;bottom: -${spacingAmount}`;
      case "top-right-top":
        return `right: 0;top: -${spacingAmount}`;
      case "bottom-right-right":
        return `bottom: 0;right: -${spacingAmount}`;
      case "bottom-left-left":
        return `bottom: 0;left: -${spacingAmount}`;
    }
  };

  const StyledHabit = styled(Habit)<StyledHabitProps>`
    position: absolute;
    display: flex;
    flex-direction: column;
    color: ${props => props.color};
    @media ${device.mobileS} {
      font-size: 2rem;
      ${props => calculateTopBottomLeftRight(props, "3rem")}
    }

    @media ${device.mobileM} {
      font-size: 2.5rem;
      ${props => calculateTopBottomLeftRight(props, "4rem")}
    }

    @media ${device.tablet} {
      font-size: 3rem;
      ${props => calculateTopBottomLeftRight(props, "3.5rem")}
    }

    @media ${device.laptop} {
      font-size: 4rem;
      ${props => calculateTopBottomLeftRight(props, "6rem")}
    }
  `;

  const StyledLabel = styled.span`
    font-family: "Poppins";
    font-weight: 500;
    @media ${device.mobileS} {
      font-size: 0.5rem;
    }

    @media ${device.mobileM} {
      font-size: 0.75rem;
    }

    @media ${device.tablet} {
      font-size: 3rem;
    }

    @media ${device.laptop} {
      font-size: 1rem;
    }
  `;

  const { icon, color, label, position } = HABIT_CONFIG[habit];
  const handleHabitPress = (e: Event) => {
    if (e.type === "mousedown") {
      handleHabitChange((value: any) => ({
        ...value,
        [habit]: value[habit] ? false : true
      }));
    }
  };
  return (
    <StyledHabit
      key={label}
      position={position}
      color={color}
      onPressStart={handleHabitPress}
      pose={today[habit] ? "visible" : "hidden"}
    >
      <i className={`${icon} `} />
      <StyledLabel>{label}</StyledLabel>
    </StyledHabit>
  );
};
